export const payfortLogo =
  "https://res.cloudinary.com/developerqa/image/upload/v1736430872/merchant/Amazon-Payment-Services_Logo_copy_ixkqm0.jpg"
export const dibsyLogo =
  "https://res.cloudinary.com/developerqa/image/upload/v1736430504/merchant/dibsy_xchibk.svg"

export const applepayLogo =
  "https://res.cloudinary.com/developerqa/image/upload/v1736422361/merchant/applepay_oope1a.png"
export const googlepayLogo =
  "https://res.cloudinary.com/developerqa/image/upload/v1739169168/GPay_Acceptance_Mark_800_cj8qvf.png"
export const refundLogo =
  "https://res.cloudinary.com/developerqa/image/upload/v1736774509/merchant/45225461_tmyasv.jpg"

export const noRefundLogo =
  "https://res.cloudinary.com/developerqa/image/upload/v1737881239/48694532_xmp67w.svg"

export const masterLogo =
  "https://res.cloudinary.com/developerqa/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1733141348/merchant/Mastercard-Logo.wine_xi5bn7.svg"
export const visaLogo =
  "https://res.cloudinary.com/developerqa/image/upload/w_1000,ar_1:1,c_fill,g_auto,e_art:hokusai/v1733141228/merchant/Visa_Inc.-Logo.wine_fuqjvs.svg"
export const amexLogo =
  "https://res.cloudinary.com/developerqa/image/upload/v1733141356/merchant/American_Express-Logo.wine_kcvada.svg"
