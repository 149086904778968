import React, { useState, useEffect } from "react"
import {
  payfortLogo,
  dibsyLogo,
  applepayLogo,
  googlepayLogo,
} from "components/SharedImages"
import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label,
  Collapse,
  Button,
} from "reactstrap"

function PaymentTypesCards(props) {
  const { paymentList, handleChangePrSettingsDetails, merchantLoading } = props
  const [open, setOpen] = useState([])
  const [paymentTypes, setPaymentTypes] = useState()
  const [ptIdsToUse, setPTIdsToUse] = useState()
  // const payfortLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736430872/merchant/Amazon-Payment-Services_Logo_copy_ixkqm0.jpg"
  // const dibsyLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736430504/merchant/dibsy_xchibk.svg"

  // const applepayLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736422361/merchant/applepay_oope1a.png"
  // const googlepayLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736422390/merchant/googlepay_vciqln.png"

  useEffect(() => {
    //console.log(paymentList)
    if (paymentList && paymentList?.length > 1) {
      let openChecks = []
      paymentList?.map(pt => {
        openChecks.push({ id: pt.id, checked: pt.checked })
      })
      setOpen(openChecks)
      setPaymentTypes(paymentList)
    } else {
      let openChecks = []
      paymentList?.map(pt => {
        openChecks.push({ id: pt.id, checked: !pt.checked })
      })
      setOpen(openChecks)
      setPaymentTypes(paymentList)
    }
  }, [paymentList])

  useEffect(() => {
    //console.log(paymentTypes)
    if (paymentTypes?.length > 0) {
      let checkedCards = []
      paymentTypes.map(pt => {
        if (pt?.checked) {
          pt.cards.map(c => {
            if (c?.checked) {
              checkedCards.push(parseInt(c.id))
            }
          })
        }
      })
      setPTIdsToUse(checkedCards)
    }
  }, [paymentTypes])
  useEffect(() => {
    //console.log(ptIdsToUse)
    if (ptIdsToUse) {
      handleChangePrSettingsDetails({
        AvailablePaymentOptions: ptIdsToUse,
      })
    }
  }, [ptIdsToUse])

  function handlePTChanges(changes, cid) {
    const newItems = [...paymentTypes]
    const item = newItems.findIndex(i => i.id === cid)

    newItems[item] = changes

    setPaymentTypes(newItems)
  }
  function handleChangePT(changes, index, cid) {
    handlePTChanges({ ...paymentTypes[index], ...changes }, cid)
  }
  function handleChangePaymentTypes(id, value, index, cid) {
    const newItems = [...paymentTypes[index].cards]
    //console.log(newItems)
    const item = newItems.findIndex(i => i.id === id)

    newItems[item] = value
    handleChangePT({ cards: newItems }, index, cid)
  }
  function handleChangeCards(id, value, index) {
    const newItems = [...paymentTypes]
    const item = newItems.findIndex(i => i.id === id)
    newItems[item] = value
    //console.log(newItems)
    setPaymentTypes(newItems)
  }
  function handleChange(id, changes, c, index, cid) {
    //console.log(id, changes, c, index)
    handleChangePaymentTypes(id, { ...c, ...changes }, index, cid)
  }
  function handleChangeCard(id, changes, card, index) {
    //console.log(changes, id)
    handleChangeCards(id, { ...card, ...changes }, index)
  }
  function handleCountCheckedCards(pg) {
    let i = 0
    if (pg.checked) {
      pg.cards.map(c => {
        if (c?.checked) {
          i++
        }
      })
    }

    return i
  }
  function handleOpenChange(id, changes) {
    const newItems = [...open]
    const item = newItems.findIndex(i => i.id === id)

    newItems[item] = changes

    setOpen(newItems)
  }
  function handleOpenClickChange(id, index, changes) {
    handleOpenChange(id, { ...open[index], ...changes })
  }

  function handlePGLogo(value) {
    switch (value) {
      case "2":
        return payfortLogo
      case "4":
        return dibsyLogo
    }
  }
  function handlePGLogoSize(value) {
    switch (value) {
      case "2":
        return "70%"
      case "4":
        return "20%"
    }
  }
  function handlePaymentOption(value) {
    switch (value) {
      case "credit":
        return ""
      case "debit":
        return ""
      case "applepay":
        return applepayLogo
      case "googlepay":
        return googlepayLogo
    }
  }
  function handlePaymentLogoHeight(value) {
    switch (value) {
      case "applepay":
        return "20px"
      case "googlepay":
        return "30px"
    }
  }
  function handlePaymentLogoClassName(value) {
    switch (value) {
      case "applepay":
        return "pointer my-1"
      case "googlepay":
        return "pointer"
    }
  }
  function handlePaymentLogoWidth(value) {
    switch (value) {
      case "applepay":
        return "50px"
      case "googlepay":
        return "53px"
    }
  }
  return (
    <React.Fragment>
      {merchantLoading ? (
        <LoadingSpinnerThreeDots />
      ) : (
        <div className="container-scroll " style={{ backgroundColor: "" }}>
          <div className="center-col ">
            {/* <Button
            color="primary"
          >
            Expand
          </Button> */}
            {/* <br /> */}
            {paymentTypes?.map((card, index = 1) => {
              return (
                <Card
                  key={index}
                  className="my-2 mx-2 pointer"
                  onClick={() => {
                    handleOpenClickChange(card.id, index, {
                      checked: !open[index]?.checked,
                    })
                  }}
                >
                  <CardBody>
                    <Input
                      checked
                      type="radio"
                      color=""
                      // onclick="javascript: return false;"
                    ></Input>
                    {/* <input
                      type="radio"
                      checked={card.checked}
                      readOnly={true}
                      style={{ border: "10px solid #90DDD0" }}
                      onClick={e => {
                        handleChangeCard(
                          card.id,
                          {
                            checked: !card.checked,
                          },
                          card,
                          index
                        )
                      }}
                      onclick="javascript: return false;"
                    /> */}
                    {/* <Label
                      check
                      className="my-auto color-primary pointer mx-2"
                      style={{ fontSize: "15px" }}
                      onClick={e => {
                        handleChangeCard(
                          card.id,
                          {
                            checked: !card.checked,
                          },
                          card,
                          index
                        )
                      }}
                     
                    >
                      {card?.pgName} {"(" + handleCountCheckedCards(card) + ")"}
                    </Label> */}
                    <img
                      src={handlePGLogo(card.id)}
                      width={handlePGLogoSize(card.id)}
                      alt="pg logo"
                      className=" pointer"
                      onClick={() => {
                        handleOpenClickChange(card.id, index, {
                          checked: !open[index]?.checked,
                        })
                      }}
                    />
                    <Collapse isOpen={!open[index]?.checked}>
                      {card.cards?.map((c, i) => {
                        if (c?.basis) {
                          return (
                            <FormGroup
                              className=" "
                              check
                              key={i}
                              style={{ width: "90%" }}
                            >
                              <Row className="mt-2 ">
                                <Col className="mx-4 ">
                                  {/* <input
                                    readOnly={true}
                                    type="radio"
                                    checked={c?.checked}
                                    className="pointer"
                                    onChange={() => {}}
                                    onClick={e => {
                                      handleChange(
                                        c.id,
                                        {
                                          checked: !c.checked,
                                        },
                                        c,
                                        index,
                                        card.id
                                      )
                                    }}
                                    onclick="javascript: return false;"
                                  /> */}
                                  <Input
                                    checked
                                    type="radio"
                                    color=""
                                    // onclick="javascript: return false;"
                                  ></Input>
                                  {handlePaymentOption(c?.basis) !== "" ? (
                                    <img
                                      src={handlePaymentOption(c?.basis)}
                                      width={handlePaymentLogoWidth(c?.basis)}
                                      height={handlePaymentLogoHeight(c?.basis)}
                                      alt="pg logo"
                                      className={handlePaymentLogoClassName(
                                        c?.basis
                                      )}
                                      // onClick={() => {
                                      //   handleOpenClickChange(card.id, index, {
                                      //     checked: !open[index]?.checked,
                                      //   })
                                      // }}
                                    />
                                  ) : (
                                    <Label
                                      check
                                      className="pointer my-1"
                                      // onClick={e => {
                                      //   handleChange(
                                      //     c.id,
                                      //     {
                                      //       checked: !c.checked,
                                      //     },
                                      //     c,
                                      //     index,
                                      //     card.id
                                      //   )
                                      // }}
                                    >
                                      {c?.basis.toUpperCase()}
                                    </Label>
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>
                          )
                        }
                      })}
                    </Collapse>
                    <br />
                  </CardBody>
                </Card>
              )
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default PaymentTypesCards
