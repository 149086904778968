import handleMultiPG, {
  handlePGIndex,
  handleMultiPGv2,
  handleMultiPGNumnber,
} from "components/PaymentTypesSelect/multiPG"
export default function PaymentListSelection(pgList, enabledList) {
  //console.log(enabledList)
  let pl = []
  pgList?.map((pgEnrollments, index) => {
    if (pgEnrollments.isActive) {
      let card = pgEnrollments.commissionSettings.map((cs, i) => {
        if (cs.percentage + cs.fixed !== 0) {
          // i = i + 1
          let csIndex =
            handlePGIndex(pgEnrollments.paymentGateway) +
            handleMultiPGNumnber(cs.basis)
          //console.log(csIndex)
          if (enabledList?.toString().includes(csIndex)) {
            return {
              id: csIndex,
              basis: cs.basis,
              value: handleMultiPG(csIndex),
              checked: true,
            }
          }
        }
      })
      card = card.filter(i => i)
      pl.push({
        id: handlePGIndex(pgEnrollments.paymentGateway),
        pgName: pgEnrollments.paymentGateway,
        cards: card,
        checked: true,
      })
      pl = pl.filter(i => i.cards?.length !== 0)
    }
  })
  // console.log(pl)
  return pl
}

export function PaymentListSelectionMerchant(pgList, enabledList) {
  //console.log(enabledList)
  let pl = []
  pgList?.map((pgEnrollments, index) => {
    if (pgEnrollments.isActive) {
      let card = pgEnrollments.commissionSettings.map((cs, i) => {
        if (cs.percentage + cs.fixed !== 0) {
          let csIndex =
            handlePGIndex(pgEnrollments.paymentGateway) +
            handleMultiPGNumnber(cs.basis)
          //console.log(csIndex)
          if (enabledList?.toString().includes(csIndex)) {
            return {
              id: csIndex,
              basis: cs.basis,
              value: handleMultiPGv2(csIndex),
              enabled: true,
            }
          } else {
            return {
              id: csIndex,
              basis: cs.basis,
              value: handleMultiPGv2(csIndex),
              enabled: false,
            }
          }
        }
      })
      pl.push({
        id: handlePGIndex(pgEnrollments.paymentGateway),
        pgName: pgEnrollments.paymentGateway,
        cards: card,
      })
    }
  })
  // console.log(pl)
  return pl
}

export function PaymentTypesMerchantEdit(pgEnrollments, enabledList) {
  //console.log(enabledList)
  let pl = []

  if (pgEnrollments.isActive) {
    pgEnrollments.commissionSettings.map((cs, i) => {
      if (cs.percentage + cs.fixed !== 0) {
        let csIndex =
          handlePGIndex(pgEnrollments.paymentGateway) +
          handleMultiPGNumnber(cs.basis)
        //console.log(csIndex)
        if (enabledList?.toString().includes(csIndex)) {
          pl.push({
            id: csIndex,
            basis: cs.basis,
            enabled: true,
          })
        } else {
          pl.push({
            id: csIndex,
            basis: cs.basis,
            enabled: false,
          })
        }
      }
    })
  }

  //console.log(pl)
  return pl
}
