import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap"
import { isMobile } from "react-device-detect"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import handleMultiPG, {
  handlePGIndex,
  handleMultiPGv2,
  handleInvoiceNameChange,
  handleMultiPGNumnber,
} from "components/PaymentTypesSelect/multiPG"
import {
  payfortLogo,
  dibsyLogo,
  applepayLogo,
  googlepayLogo,
  masterLogo,
  visaLogo,
  amexLogo,
} from "components/SharedImages"
// import visalogo from "../../../public/Visa_Inc.-Logo.wine.svg"

function CardTypesCheckBox(props) {
  const { paymentRequestDetails, setSelectCard, paymentCards } = props
  const [paymentTypes, setPaymentTypes] = useState([])
  const [selected, setSelected] = useState()
  const [cardArrays, setCardArrays] = useState()
  //-------------------------------------------------------------------------
  useEffect(() => {
    //console.log(paymentRequestDetails)
    // console.log(paymentCards)
    setCardArrays(hanldePaymentTypeCards(paymentRequestDetails))
  }, [paymentRequestDetails])

  useEffect(() => {
    //console.log(cardArrays)
    if (cardArrays) {
      //console.log(isSafariBrowser())
      let arr = handleSort(cardArrays)
      if (!isSafariBrowser()) {
        arr = cardArrays.filter(
          c => !String(c?.basis).toLocaleLowerCase().includes("applepay")
        )
      }
      //console.log(arr)
      setSelectCard(arr[0])
      setPaymentTypes(arr)
    }
  }, [cardArrays])
  useEffect(() => {
    //console.log(paymentTypes)
  }, [paymentTypes])
  useEffect(() => {
    // console.log(selected)
    if (selected) {
      setSelectCard(cardArrays.find(pt => pt?.basis === selected))
    }
  }, [selected])

  //-------------------------------------------------------------------------
  function isSafariBrowser() {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    return isSafari
  }
  function handleSort(value) {
    let sortedOptions = value.sort(
      (a, b) => a.id.toString().slice(1) - b.id.toString().slice(1)
    )
    return sortedOptions
  }
  function hanldePaymentTypeCards(props) {
    let pt = []
    // let ind = 1
    //console.log(props)
    props.map((pg, index) => {
      pg.convenienceFees.map((cf, i) => {
        pt.push({
          id: handlePGIndex(pg.pgId) + handleMultiPGNumnber(cf.basis),
          basis: cf.basis,
          fixed: cf.fixed,
          percentage: cf.percentage,
        })
      })
      // ind++
    })

    let cards = paymentCards?.map(pc => {
      // console.log(pc)
      let ptTypes = pt.find(p => {
        return pc.toString() === p.id
      })
      // console.log(ptTypes)
      if (ptTypes) {
        return {
          id: ptTypes.id,
          basis: ptTypes.basis,
          fixed: ptTypes.fixed,
          percentage: ptTypes.percentage,
        }
      } else {
        return {
          id: pc.toString(),
          basis: handleMultiPGv2(pc.toString()),
          fixed: 0,
          percentage: 0,
        }
      }
    })

    return cards
  }
  //-----------------------------------------------------------------------
  return (
    <React.Fragment>
      <Row>
        <Col></Col>
        <Col md={8} className="">
          <Row className="mt-2">
            <Col className="item-left">
              <small className="color-danger">
                {props.t("Select payment method")}
              </small>
            </Col>
          </Row>
          <Row className="my-1">
            <Col>
              <select
                className="form-control pointer"
                value={selected?.basis}
                onChange={e => {
                  //console.log(e)
                  setSelected(e.target.value)
                }}

                // ref={register({ required: true })}
              >
                {paymentTypes?.map((pt, i) => {
                  return (
                    <option value={pt?.basis} key={i}>
                      {props.t(handleInvoiceNameChange(pt?.basis))}
                      {/* {pt?.fixed + pt?.percentage !== 0
                        ? pt?.fixed !== 0
                          ? " + " + pt?.fixed + props.t(" QAR")
                          : " + " + pt?.percentage + props.t(" %")
                        : null} */}
                    </option>
                  )
                })}
              </select>
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col sm={10}>
          <img
            src={masterLogo}
            width={"50px"}
            height={"50px"}
            alt="master logo"
          />
          <img
            src={visaLogo}
            width={"50px"}
            height={"50px"}
            alt="visa logo"
            className="mx-2"
          />

          <img src={amexLogo} width={"50px"} height={"50px"} alt="amex logo" />
        </Col>
        <Col> </Col>
      </Row>
      <Row className="mb-2">
        <Col></Col>
        <Col sm={10}>
          <span
            style={{
              border: "1px solid",
              borderRadius: "0.05rem",
              padding: "2px",
              fontSize: "13px",
              fontStyle: "italic",
              verticalAlign: "middle",
            }}
          >
            NAPS
          </span>
          <img
            src={applepayLogo}
            width={"50px"}
            height={"20px"}
            alt="apple pay logo"
            className="mx-2"
          />

          <img
            src={googlepayLogo}
            width={"55px"}
            height={"30px"}
            alt="google pay logo"
          />
        </Col>
        <Col> </Col>
      </Row>
      <div>
        <small>
          <a
            href={paymentRequestDetails?.termsConditionUrl}
            rel="noopener noreferrer"
            target="_blank"
            className="color-primary"
          >
            {props.t("By paying, I agree to the TERMS & CONDITION")}
          </a>
        </small>
      </div>{" "}
    </React.Fragment>
  )
}
CardTypesCheckBox.propTypes = {
  t: PropTypes.any,
}

export default withRouter(withTranslation()(CardTypesCheckBox))
