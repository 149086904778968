export const PaymentOptions = {
  APS: 20, // LABEL / PLACEHOLDER
  APS_Credit: 21,
  APS_Debit: 22,
  APS_Applepay: 23,

  Dibsy: 40, // LABEL / PLACEHOLDER
  Dibsy_Credit: 41,
  Dibsy_Debit: 42,
  Dibsy_Applepay: 43,
  Dibsy_Googlepay: 44,
}

export default function handleMultiPG(value) {
  // console.log(value)
  switch (value) {
    case "21":
      return "APS Credit"
    case "22":
      return "APS Debit"
    case "23":
      return "APS Applepay"
    case "41":
      return "Dibsy Credit"
    case "42":
      return "Dibsy Debit"
    case "43":
      return "Dibsy Applepay"
    case "44":
      return "Dibsy Googlepay"
  }
}

export function handleMultiPGv2(value) {
  // console.log(value)
  switch (value) {
    case "21":
      return "credit"
    case "22":
      return "debit"
    case "23":
      return "applepay"
    case "41":
      return "credit"
    case "42":
      return "debit"
    case "43":
      return "applepay"
    case "44":
      return "googlepay"
  }
}

export function handleMultiPGNumnber(value) {
  // console.log(value)
  switch (value) {
    case "credit":
      return "1"
    case "debit":
      return "2"
    case "applepay":
      return "3"
    case "googlepay":
      return "4"
  }
}

export function handlePGIndex(value) {
  switch (value) {
    case "Amazon Payment Services":
      return "2"
    case "Dibsy":
      return "4"
    case "PG00002":
      return "2"
    case "PG00004":
      return "4"
  }
}

export function handleInvoiceNameChange(value) {
  switch (value) {
    case "credit":
      return " Credit Card"
    case "debit":
      return " Qatar Debit Card"
    case "googlepay":
      return " Google Pay"
    case "applepay":
      return " Apple Pay"
    default:
      return value
  }
}
